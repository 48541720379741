export const environment = {
  holdingPage: true,
  production: false,
  apiUrl: 'https://developherawards.hasura.app/v1/graphql',
  recaptchaKey: '6LcBZDUqAAAAABlDjRY7hCJ6FnFdmhcRq6cje-Ve',
  cognitoUserPoolId: 'eu-west-2_5uQhRL2g7',
  cognitoAppClientId: 'cia9fpteq31orm7hctbruul8a',
  nominationsCloseDate: '2024-10-01 23:59:59',
  nominationsOpenDate: '2025-07-01 00:00:00',
};
